


















import { Component, Vue, Watch } from "vue-property-decorator"
import Item from "@/components/item.vue"

@Component({
	components: {
		Item
	}
})
export default class tabbar extends Vue {
	// 初始化数据
	// selected: string = ""
	tabbarDes: { txt: string; page: string; normalImg: any; activeImg: any }[] = [
		{
			txt: "首页",
			page: "",
			normalImg: require("../assets/img/homeGrey.png"),
			activeImg: require("../assets/img/homeLight.png")
		},
		{
			txt: "我的",
			page: "my",
			normalImg: require("../assets/img/myGrey.png"),
			activeImg: require("../assets/img/myLight.png")
		}
	]

	@Watch("$route.path")
	getWatchValue(newVal: string, oldVal: string) {
		console.log(newVal, oldVal)
	}

	get selected(){
		if (this.$route.path.indexOf("my") != -1) {
			return "my"
		} else {
			return  ""
		}
	}

	getVal(val: string) {
		// console.log(val)
		// this.selected = val
	}

	created() {
		// if (window.location.hash.indexOf("my") != -1) {
		// 	this.selected = "my"
		// } else {
		// 	this.selected = ""
		// }
	}
}
