













import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class item extends Vue {
	@Prop(String) txt!: string
	@Prop(String) page!: string
	@Prop(String) sel!: string

	get bol() {
		if (this.sel == this.page) {
			return true
		} else {
			return false
		}
	}

	changePage() {
		//点击跳转对应的页面
		this.$router.push("/" + this.page).catch((err:any)=>{err})
		this.$emit("change", this.page)
	}
}
